const theme = {
  // Colours
  textColor: '#171616',
  textColorLight: '#a7a7a7',

  // Fonts
  fontSize: '1.8rem',
  fontFamily:
    'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
  fontFamilyHeading:
    'PlayFair, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',

  // Sizing
  maxContainerWidth: '1160px',
  maxBigContainerWidth: '1240px',
  defaultMargin: '0 0 12.5rem',

  // Breakpoints
  respondTo: {
    desktopSuperBig: `min-width: ${1600 / 16}em`,
    desktopExtraBig: `min-width: ${1440 / 16}em`,
    desktopBig: `min-width: ${1280 / 16}em`,
    desktop: `min-width: ${1024 / 16}em`,
    tablet: `min-width: ${768 / 16}em`,
    mobile: `max-width: ${480.99999 / 16}em`,
  },
};

export default theme;
