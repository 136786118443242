import React from 'react';
import { ThemeProvider } from 'styled-components';

import GlobalStyle from '../styling/global';
import theme from '../styling/theme';

const Layout = ({ children, isVisible }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <main>{children}</main>
      </>
    </ThemeProvider>
  );
};

export default Layout;
