import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Typist from 'react-typist';

import Layout from './components/Layout';
import Container from './components/Container';
import Heading from './components/typography/Heading';

const Hero = styled.section`
  position: relative;
  display: flex;
  /* align-items: center; */
  height: 100vh;
  margin-top: 10vh;

  @media (${({ theme }) => theme.respondTo.mobile}) {
    margin-top: 5vh;
  }

  > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    text-align: center;
    transform: translate(-50%, -50%);
  }
`;

const Button = styled.button`
  display: inline-flex;
  justify-content: center;
  appearance: none;
  width: 10rem;
  height: 10rem;
  background: green;
  border-radius: 50%;
  overflow: hidden;
  color: white;
  font-size: 4rem;
  font-weight: 400;
  text-align: center;
  margin-top: 6rem;
  cursor: pointer;
  transition: all 0.25s;

  &:hover {
    transform: scale(1.25);
  }
`;

const VideoWrapper = styled.div`
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  position: relative;
`;

const IFrame = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
`;

const App = () => {
  const [isVisible, setVisibility] = useState(0);
  const [showZeroButton, setShowZeroButton] = useState(false);
  const [showFirstButton, setShowFirstButton] = useState(false);
  const [showSecondButton, setShowSecondButton] = useState(false);

  return (
    <Layout>
      <Container>
        <Hero>
          {isVisible === 0 && (
            <div>
              <Heading big>
                <Typist onTypingDone={() => setShowZeroButton(true)}>
                  Hoi Charles. <br />
                  Ben je er klaar voor?
                </Typist>
              </Heading>
              {showZeroButton && (
                <Button onClick={() => setVisibility(1)}>JA</Button>
              )}
            </div>
          )}
          {isVisible === 1 && (
            <Heading big>
              <Typist onTypingDone={() => setShowFirstButton(true)}>
                Lieve Charles,
                <Typist.Delay ms={1000} />
                <br />
                64 jaar, <Typist.Delay ms={750} />
                wat een net-niet
                <Typist.Backspace count={8} delay={200} />
                schitterende leeftijd! <Typist.Delay ms={1000} />
                Dat kunnen we helaas niet zeggen over de huidige periode...{' '}
                <Typist.Delay ms={1000} />
                Vandaar dit keer een cadeautje in een andere vorm.{' '}
                <Typist.Delay ms={1000} />
                Geheel RIVM-proof. <Typist.Delay ms={1000} />
                Klaar voor?
              </Typist>
              {showFirstButton && (
                <Button onClick={() => setVisibility(2)}>JA</Button>
              )}
            </Heading>
          )}
          {isVisible === 2 && (
            <Heading big>
              <Typist onTypingDone={() => setShowSecondButton(true)}>
                Eerst nog even dit. <Typist.Delay ms={1000} />
                Dit cadeau komt het beste tot zijn recht als jij zo optimaal
                mogelijk in verjaardagsmodus gaat zitten.{' '}
                <Typist.Delay ms={1000} />
                Dus pak een taartje, een glas wijn <Typist.Delay ms={500} />
                (of koffie)
                <Typist.Delay ms={250} /> en een comfortabele stoel.{' '}
                <Typist.Delay ms={1000} />
                Ben je er nu echt klaar voor?
              </Typist>
              {showSecondButton && (
                <Button onClick={() => setVisibility(3)}>JA!</Button>
              )}
            </Heading>
          )}
          {isVisible === 3 && (
            <div>
              <VideoWrapper>
                <IFrame
                  width="100%"
                  height="auto"
                  src={`https://www.youtube.com/embed/C0tu3C-iqyE?autoplay=1`}
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                />
              </VideoWrapper>
            </div>
          )}
        </Hero>
      </Container>
    </Layout>
  );
};

export default App;
