import React from 'react';
import styled from 'styled-components';

const StyledHeading = styled.h1`
  position: relative;
  color: ${({ theme }) => theme.textColor};
  line-height: 1.15;
  font-size: 6rem;
  + p,
  + ul,
  + div {
    margin: 1.5rem 0 0;
  }
  @media (${({ theme }) => theme.respondTo.mobile}) {
    font-size: 2.95rem;
  }
`;

const Heading = ({ children, as }) => {
  return <StyledHeading as={as}>{children}</StyledHeading>;
};

export default Heading;
