import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import PlayFairWoff from '../fonts/playfairdisplay-bold.c334efab.woff';
import PlayFairWoff2 from '../fonts/playfairdisplay-bold.c377f53b.woff2';
import InterWoff from '../fonts/Inter-Regular.woff';
import InterWoff2 from '../fonts/Inter-Regular.woff2';

// set basic styling and 'normalize' things
const GlobalStyle = createGlobalStyle`  

	/* @font-face example */
	@font-face {
    font-family: 'PlayFair';
    src: url(${PlayFairWoff}) format('woff'),
      url(${PlayFairWoff2}) format('woff2');
    font-weight: normal;
    font-style: normal;
  }

	@font-face {
    font-family: 'Inter';
    src: url(${InterWoff}) format('woff'),
      url(${InterWoff2}) format('woff2');
    font-weight: bold;
    font-style: normal;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }
  
  body {
    padding: 0;
    margin: 0;
    height: auto;
    width: 100%;
    overflow: auto;
    font-size: ${({ theme }) => theme.fontSize};
		line-height: 1.5;
    font-family: ${({ theme }) => theme.fontFamily};
    background-color: #FFFFFF;
		color: ${({ theme }) => transparentize(0.2, theme.textColor)};
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    font-weight: bold;
    font-family: ${({ theme }) => theme.fontFamilyHeading};
		line-height: 1;
		color: ${({ theme }) => theme.headingColor};
  }

	h1 {
		font-size: 7rem;
		@media screen and (max-width: 414px) {
			font-size: 4rem;
		}
	}

	h2 {
		font-size: 2.6rem;
	}

	h3, h4 {
		font-size: 2.2rem;
	}

  p {
    margin: 0;
    padding: 0;
		font-size: ${({ theme }) => theme.fontSize};
		+ p {
			margin: 1em 0 0;
		}
  }

  a {
    color: inherit;
    text-decoration: none;
	}
	
	img {
		max-width: 100%;
	}
`;

export default GlobalStyle;
